import { render, staticRenderFns } from "./KPI.vue?vue&type=template&id=008dcf85&scoped=true&"
import script from "./KPI.vue?vue&type=script&lang=js&"
export * from "./KPI.vue?vue&type=script&lang=js&"
import style0 from "./KPI.vue?vue&type=style&index=0&id=008dcf85&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "008dcf85",
  null
  
)

export default component.exports